/*============ Sidebar Layout ===========*/

.rct-page-wrapper {
  [direction="right"] {
    border-right: none;
    z-index: 999 !important;
  }
}
.rct-sidebar {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $primary-light;
  width: $sidebar-width;
  background-position: top center;
  overflow: hidden;
  transition: all 200ms ease 0s;
  height: 100vh;
  color: $primary-dark;
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 7px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: $thumbScrollColor;
    }
  }
  .rct-sidebar-content {
    background-color: $primary-light;
    position: relative;
    z-index: 0;
    width: 86px;
    .rct-scroll {
      div:first-child {
        display: flex;
        flex-direction: column;
      }
      .user-profile-bottom {
        flex-direction: column;
        display: flex;
        margin-bottom: 41px;
        height: 82px;
        cursor: pointer;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      z-index: -1;
      opacity: 0.9;
    }
    &.sidebar-overlay-dark:before {
      background-color: $primary-light;
    }
    &.sidebar-overlay-light:before {
      background-color: $primary-light;
    }
  }
  &.background-none .rct-sidebar-content:before {
    opacity: 1 !important;
  }
  .site-logo {
    padding: 0.89rem 1.5rem; //15px 30px;
    align-items: center;
    display: flex;
  }
  .rct-sidebar-wrap {
    .top-sidebar {
      .sidebar-user-block {
        padding: 1.25rem; //20px;
        .rct-dropdown {
          cursor: pointer;
          .dropdown-menu {
            background-color: $base-light;
            width: 200px;
            ul {
              background-color: transparent;
              li::nth-last-child(2) {
                flex: 1;
              }
              li:not(.user-profile-top) {
                margin: 0.3125rem;

                a {
                  background-color: $white;
                  padding: 0.75rem 1rem;
                  display: block;
                  i {
                    font-size: 1rem;
                  }
                  span:not(.badge) {
                    color: #365b7c;
                    font-size: $font-size-sm;
                    transition: color 0.3s ease;
                  }
                  &:hover {
                    span:not(.badge) {
                      color: $sidebar-link-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .user-profile {
    > img {
      @include border(3px solid, $white, top bottom left right);
    }
  }
  .rct-sidebar-nav {
    .rct-mainMenu {
      li {
        &.side-title {
          font-size: 0.75rem;
          line-height: 1.25rem;
          position: static;
          padding: 0.5rem 1.5rem !important;
        }
        &.list-item {
          position: relative;
          color: $sidebar-link-font-color;
          .menu-icon {
            font-size: 1.25rem;
            width: 100%;
            margin: 0 auto;
          }
          span.menu {
            font-size: 0.875rem;
          }
          &:after {
            position: absolute;
            content: "\f2fb";
            font-family: Material-Design-Iconic-Font;
            right: 1.5rem;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            transform: rotate(0);
            transition: all 0.2s ease-in-out;
          }
          &.item-active {
            background-color: rgba(0, 0, 0, 0.2);
            &:after {
              transform: rotate(90deg);
            }
            span.menu {
              font-weight: $fw-semi-bold;
            }
          }
        }
      }
      > li:not(.side-title) {
        padding: 1rem 1.5rem;
      }
      > li:not(.list-item) {
        padding: 0;
        display: block;
        a {
          display: block;
          padding: 1rem 1.5rem;
        }
      }
      .sub-menu {
        ul {
          li {
            padding: 0;
            display: block;
            a {
              display: block;
              padding: 0.75rem 1.5rem 0.75rem 3.4rem;
              text-transform: capitalize;
              &.item-active {
                font-weight: $fw-semi-bold;
              }
            }
            &.list-item {
              padding: 0.75rem 1.5rem 0.75rem 3.4rem;
            }
          }
        }
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    transform: translate3d(0px, 50px, 0px);
    padding: 0;
  }
}

.rct-sidebar {
  .sidebar-overlay-dark {
    * {
      color: #546e82;
    }
  }
  .sidebar-overlay-light {
    * {
      color: #546e82;
    }
    .site-logo {
      background-color: $primary;
    }
  }
}

.collapsed-sidebar {
  .rct-header {
    left: 0;
  }
  .rct-sidebar {
    width: 0;
  }
  .rct-app-content {
    width: 100%;
  }
}

/*========= Customizer ==========*/

.rct-customizer {
  width: $sidebar-width;
  overflow-x: hidden;
  header {
    background: $gray-900;
    color: #365b7c;
  }
  .chat-area {
    .chat-head {
      @include border(1px solid, $border-color, top);
    }
    .chat-head,
    .chat-body {
      padding: 0.625rem; //10px;
    }
    .chat-body {
      .media-body {
        padding: 0.625rem !important;
        margin: 3px;
      }
    }
    .attachment {
      align-items: center;
      display: flex;
    }
    h3 {
      font-size: 14px;
      margin: 0;
    }
    .send-icons {
      ul {
        > li {
          &:nth-last-of-type(-n + 2) {
            display: none;
          }
        }
      }
    }
    .chat-footer {
      padding: 0;
    }
  }
  .chat-sidebar {
    .badge-xs {
      padding: 5px;
    }
    .media-body {
      h5 {
        font-size: 14px;
      }
    }
  }
  .panel-title {
    font-size: 14px;
    font-weight: bold;
    margin: 10px;
  }
}

.new-item {
  height: 20px !important;
  background-color: #e53935 !important;
  position: absolute;
  right: 40px;
  span {
    padding: 0 9px !important;
    color: #fff !important;
    text-transform: capitalize;
  }
  &:hover {
    span {
      color: #fff !important;
    }
  }
}
