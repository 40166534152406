/*========== Drag And Drop Scss =======*/
.table-dragula {
  tbody {
    tr {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      td.w-5 {
        text-align: center;
      }
    }
  }
}

.drag-list-wrapper {
  li {
    @include background-opacity($primary, 0.2);
    border: none;
    .drag-list {
      @include background-opacity($primary, 0.9);
      padding: 0.9375rem 0.625rem;
      color: $light;
    }
  }
}

.tradedash-main-logo-to-animate {
  background-color: aquamarine;
  @-webkit-keyframes fadein {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadein {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 1;
    }
  }

  -webkit-animation: fadein 0.85s ease-in alternate infinite;
  -moz-animation: fadein 0.85s ease-in alternate infinite;
  animation: fadein 0.85s ease-in alternate infinite;
}
