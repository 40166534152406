mark {
  padding: 0 !important;
  background-color: #ffff00;
  font-weight: bold;
}
.purchase-order-list-view {
  height: calc(100vh - 80px);
  width: 100%;

  background-color: rgb(246, 251, 255);
  table {
    min-width: 100%;
  }
  tbody {
    td {
      border-bottom: 1px solid $primary-light;
    }
  }
  thead,
  tbody {
    td,
    th {
      color: #25486d;
      &:nth-child(1) {
        width: 35px;
        min-width: 35px;
        max-width: 35px;
      }
      &:nth-child(2) {
        width: 35px;
        min-width: 35px;
        max-width: 35px;
      }
      &:nth-child(3) {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }
      &:nth-child(4) {
        // width: 70px;
        // min-width: 70px;
        // max-width: 70px;
      }
      &:nth-child(5) {
        // width: 70px;
        // min-width: 70px;
        // max-width: 70px;
      }
      &:nth-child(6) {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
      }
      &:nth-child(7) {
        width: 130px;
        min-width: 130px;
        max-width: 130px;
      }
      &:nth-child(8) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(9) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(10) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(11) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(12) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(13) {
        width: 95px;
        min-width: 95px;
        max-width: 95px;
      }
      &:nth-child(14) {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
      }
      &:nth-child(15) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(16) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(17) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(18) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(19) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }
      &:nth-child(20) {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
      }
      &:nth-child(21) {
        width: 130px;
        min-width: 130px;
        max-width: 130px;
      }
      &:nth-child(22) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(23) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
      &:nth-child(24) {
        width: 140px;
        min-width: 140px;
        max-width: 140px;
      }
    }
  }
  thead {
    background-color: $primary-light;
    position: sticky;
    // top: 140px;
    tr {
      min-height: 50px;
      height: 40px;
      position: sticky;
      // top: 140px;
      &:nth-child(2) {
        th {
          position: sticky;
          top: 155px;
          svg {
            width: 20px;
          }
        }
      }
    }
    th {
      &.sortedColumn {
        font-weight: 900;
        .material-icons {
          opacity: 1;
          color: #39f;
        }
      }
      position: sticky;
      top: 110px;
      background-color: $primary-light;
      z-index: 9;
      cursor: pointer;
      &:hover {
        .material-icons {
          opacity: 1;
        }
      }
      .material-icons {
        position: relative;
        width: 20px;
        height: 10px;
        color: #7c8a99;
        opacity: 0;
        svg {
          width: 20px;
          height: 18px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
      &:nth-child(9),
      &:nth-child(11),
      &:nth-child(13),
      &:nth-child(15),
      &:nth-child(16),
      &:nth-child(17),
      &:nth-child(18),
      &:nth-child(20),
      &:nth-child(21),
      &:nth-child(22),
      &:nth-child(23) {
        .material-icons {
          position: absolute;
          right: -7px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:nth-child(20),
      &:nth-child(21),
      &:nth-child(22),
      &:nth-child(23) {
        .material-icons {
          right: 0px;
        }
      }
    }
  }
  tfoot {
    min-height: 50px;
    td {
      padding-top: 10px;
      &.divider {
        border-top: 3px solid $blue;
        font-weight: 500;
        top: -2px;
        position: relative;
      }
      &.total-label {
        font-weight: 700;
        color: $primary-dark;
      }
    }
  }
  th {
    min-width: 60px;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    position: relative;
    position: sticky;
    top: 140px;
    background-color: $primary;
  }
  th.double-header {
    column-span: 2;
    min-width: 180px;
  }

  td:first-child,
  td:nth-child(2),
  td:nth-child(3) {
    width: 28px;
  }
  td {
    min-width: 28px;
    max-width: 185px;
    padding: 6px 10px;
    font-weight: 400;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .MuiCircularProgress-static {
      position: relative;
      top: 2px;
      background: #f2f2f2;
      border-radius: 100%;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        background: white;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
  .dateSeparator {
    padding: 0 17px;
    background-color: $primary-dark;
    color: white;
    width: 220px;
    font-size: 14px;
    font-weight: bold;
    .dateMonth,
    .dateQuant {
      padding: 12px 0;
      display: inline-block;
    }
    .dateMonth {
      padding-right: 8px;
    }
    .dateQuant {
      border-left: 1px solid #4b729b;
      float: right;
      padding-left: 13px;
    }
  }
  .filters-section {
    padding-top: 16px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    width: 50%;
    button {
      color: $primary-dark;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 40px;
      width: 105px;
    }
    .vertical-divider {
      height: 42px;
      width: 1px;
      background-color: #e0e0e0;
      margin-right: 4px;
    }
  }
  .main-content {
    .secondary-filters {
      display: flex;
      flex-direction: column;
      padding: 35px 16px;
      font-size: 14px;
      color: #aaa;
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: #fff;
      &:before {
        content: "";
        height: 110px;
        width: 380px;
        position: absolute;
        top: 0;
        left: 100%;
        background: #f6fbff;
      }
      .filters-container {
        display: flex;
        flex: 1;
        align-items: center;
        .filter-display {
          height: 40px;
          border: 1px solid #d2dfec;
          padding: 2px;
          border-radius: 4px;
          margin-right: 8px;
          min-width: 200px;

          .filter-input {
            padding: 0;
            padding-left: 9px;
            padding-right: 12px;
            color: #25486d;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80%;
          }
          .filter-focused {
            color: #888;
          }
          .filter-underline::after {
            display: none;
          }
          .filter-underline::before {
            display: none;
          }
        }
      }
    }
    .list-header {
      background-color: $primary-light;
      font-size: 13px;
      font-weight: bold;
      padding: 8px 0;
      .horizontal-divider {
        height: 3px;
        margin-bottom: 0;
        opacity: 0.2;
        background-color: $primary-dark;
        width: 90%;
        bottom: 0;
        position: absolute;
      }
      .main-header {
        align-items: flex-end;
        height: 100%;
        padding: 0;
        justify-self: center;
        div {
          display: inline-flex;
          vertical-align: baseline;
          align-items: flex-end;
        }
      }
    }
    .PO-item {
      font-size: 14px;
      height: 45px;
      .number-text {
        text-decoration: underline;
        color: $primary-dark;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
      }
      .favorite-inactive {
        display: none;
      }
    }
    .PO-item:hover {
      background-color: rgb(246, 251, 255);
      user-select: none;
      .favorite-inactive {
        display: block;
      }
    }
  }
}
.PO-list-select-paper {
  max-height: calc(100% - 451px) !important;
  box-shadow: none;
  border-radius: 0 !important;
  border: 1px solid #aaa;
  top: 120px !important;
  .select-item {
    display: flex;
    align-items: center;
    padding: 0px 0px;
    min-height: 0;
    label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      cursor: pointer;
    }
  }
  .select-item:hover {
    background-color: $blue;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 0px;
    min-height: 0;
    label {
      margin: 0;
      cursor: pointer;
    }
  }

  .padding-list {
    padding: 0;
  }
}
.general-filter-sm {
  min-width: 50px;
  width: 50px;
  max-width: 50px;
}

.general-filter {
  :hover {
    background-color: #e0e3e7;
  }
  .MuiFilledInput-root {
    transition: none;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 24px) scale(1);
    transform-origin: top left;
  }
  .customer-filter-label {
    label {
      margin: 0;
      transform: translate(0, 24px) scale(1) !important;
    }
  }
}

.select-filter-control {
  cursor: pointer;
  @media screen and (max-width: 1425px) {
    min-width: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
  }

  &:hover {
    background-color: #e0e3e7;
  }

  .customer-filter-label {
    pointer-events: none;
    transform: translate(0, 24px) scale(1) !important;
    color: $primary-dark !important;
    display: flex !important;
    align-items: flex-end !important;
    user-select: none;
    .location-icon {
      margin-right: 8px;
      margin-bottom: 2px;
    }
    label {
      margin: 0;
    }
  }

  .select {
    margin: 0;
    margin-right: 8px;
    background-color: white !important;
    border-radius: 3px;
    width: 180px;
    border: 1px solid #aaa;
    height: 40px;
    @media screen and (max-width: 1425px) {
      min-width: 50px !important;
      width: 50px !important;
      max-width: 50px !important;
    }
  }
  .select::before {
    border: none;
  }
  .select:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .select::after {
    border: none;
  }
}
.tooltip-filter {
  display: flex;
  flex-wrap: wrap;
}
.PO-footer {
  padding: 8px;
  border-top: 2px solid #ccc;
  font-size: 14px;
  .sum-divider {
    position: absolute;
    top: -2.6px;
    height: 3px;
    width: calc((100% / 12) * 2.9);
    background-color: $blue;
    border-radius: 10px;
  }
  .amount-container {
    color: #1d9531;
    font-weight: 600;
  }
}

.filter-display-sm {
  height: 40px !important;
  border: 1px solid #d2dfec !important;
  padding: 2px !important;
  border-radius: 4px !important;
  margin-right: 8px !important;
  min-width: 50px !important;
  max-width: 80px;

  .filter-input {
    padding: 0;
    padding-left: 2px;
    padding-right: 12px;
    color: #25486d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
  }
  .filter-focused {
    color: #888;
  }
  .filter-underline::after {
    display: none;
  }
  .filter-underline::before {
    display: none;
  }
}

.filter-display {
  .filter-input {
    padding: 0;
    padding-left: 9px;
    padding-right: 12px;
    color: #25486d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
  }
  .filter-focused {
    color: #888;
  }
  .filter-underline::after {
    display: none;
  }
  .filter-underline::before {
    display: none;
  }
}

.filter-display-md {
  .filter-input {
    padding: 0;
    padding-left: 9px;
    padding-right: 12px;
    color: #25486d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
  }
  .filter-focused {
    color: #888;
  }
  .filter-underline::after {
    display: none;
  }
  .filter-underline::before {
    display: none;
  }
}
