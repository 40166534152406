.form-group-option-buttons {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  div {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

.create-user-form-group {
  display: flex;
  margin-bottom: 0;
}

.react-phone-input {
  input {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    line-height: 25px;
    border-radius: 0;
    height: 24px;
    width: 61%;
    outline: none;
  }
}
.permissionGroupContainerForm {
  display: flex;
  justify-content: flex-start;
  margin: 4px 0;
  font-size: 12px;
  height: 25px;
  align-content: center;
  width: 450px;
  label {
    padding: 7px;
  }
  input {
    font-size: 12px;
    border: 1px solid #ccc;
    width: 80%;
  }
  .inputError {
    color: red;
    position: absolute;
    top: 25px;
    margin-left: 8px;
    font-size: 11px;
  }
}
.form-input {
  display: flex;
  margin: 4px 0;
  font-size: 12px;
  width: 380px;
  justify-content: space-between;
  .phoneInputSettings {
    height: 21.8px;
    width: 250px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: unset;
    border-bottom: unset;
    box-shadow: unset;
    display: flex;
    .flag-dropdown.open {
      .country-list .flag {
        position: unset;
      }
    }

    .form-control {
      height: 21.8px;
      width: 250px;
      font-size: 12px;
      padding: 0px 14px 0px 58px;
      border-radius: 0px;
      border-top: unset;
      border-left: unset;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      box-shadow: unset;
      &:focus {
        border-top: unset;
        border-left: unset;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        box-shadow: unset;
      }
      &:hover {
        border-top: unset;
        border-left: unset;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        box-shadow: unset;
      }
    }
    .flag-dropdown {
      border-top: unset;
      border-left: unset;
      border-right: 1px solid #ccc;
      border-bottom: unset;
    }
    .special-label {
      visibility: hidden;
    }
  }
  label {
    min-width: 100px;
  }
  input {
    font-size: 12px;
    border: 1px solid #ccc;
    width: 170px;
  }
  select {
    font-size: 12px;
    width: 170px;
    border: 1px solid #ccc;
  }
}
