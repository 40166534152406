.task-checkbox {
  display: flex;
  margin: 4px 8px;
  user-select: none;
  align-items: center;
}
.timer-checkbox {
  display: flex;
  margin: 4px 8px 4px 8px;
  user-select: none;
  vertical-align: center;
}

.date-picker {
  min-width: 100px;
  user-select: none;
}

.phase-picker {
  ul {
    margin: 0;
    .phase-item-selected {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $primary-dark;
      cursor: pointer;
      background-color: $selected-background;
    }
    .phase-item-selected:hover {
      font-weight: 700;
    }
    .phase-item {
      padding: 8px;
      font-size: 14px;
      font-weight: 400;
      color: $primary-dark;
      cursor: pointer;
    }
    .phase-item:hover {
      background-color: $on-hover-background;
      font-weight: 500;
    }
  }
}

.user-picker {
  ul {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 0;
    li {
      cursor: pointer;
      padding: 6px 28px;
      border-bottom: 1px solid #ccc;
      display: flex;
      align-items: center;
      color: $primary-dark;
      font-size: 14px;
      font-weight: 400;
      p {
        margin: 0;
      }
      p::before {
        content: "@";
      }
    }
    .user-option:hover {
      background-color: $on-hover-background;
      font-weight: 500;
    }
    .user-disable {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .user-disable:hover {
      background-color: $on-hover-background;
      font-weight: 500;
    }
    .user-selected {
      background-color: $selected-background;
      font-weight: 500;
    }
    .user-selected:hover {
      font-weight: 700 !important;
    }
  }
}

.paper-menu-container {
  top: 52px !important;
}
.Nav-picker {
  .MuiPopover-paper {
    width: auto;
    min-width: 175px;
    min-height: 35px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 0px;
    max-height: calc(100vh - 65px);
    .Mui-selected {
      background-color: unset;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
  .list-navpick {
    display: flex;
    padding: 5px 25px 5px 16px;
    cursor: pointer;
    align-items: center;
    &:hover {
      background: #f5f5f5;
    }
    .item-listnumber {
      color: #25486d;
    }
    .item-divider {
      margin-inline: 12px 18px;
      width: 13px;
      height: 100%;
      margin-top: 6px;
      color: #25486d;
    }
    .item-subtitle {
      color: #25486d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 240px;
    }
  }
  .list-navpick-selected {
    @extend .list-navpick;
    box-shadow: 4px 0px 0px 0px #218cff inset;
    background: transparent;
  }
}
.list-picker {
  .MuiPopover-paper {
    width: 619px;
    min-height: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .table-container {
    max-height: 235px;
    overflow-y: overlay;
    .cellHead-list-picker {
      color: #25486d;
      background-color: #cae0fc;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      svg {
        top: 3px;
        opacity: 0;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
    .cellHead-list-picker-sorted {
      color: #25486d;
      background-color: #cae0fc;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      svg {
        top: 3px;
        opacity: 1;
        color: #3399ff;
      }
    }
    .cell-list-picker {
      color: #25486d;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    .row-list-picker {
      &:hover {
        td {
          font-weight: 500;
        }
        background: #fefce7;
        cursor: pointer;
      }
    }
    .row-list-selected {
      background: #ebebeb;
      .cell-list-picker {
        color: #25486d;
        font-weight: 500;
      }
      &:hover {
        background: #ebebeb;
        cursor: pointer;
        td {
          font-weight: 700;
        }
      }
    }
  }

  .new-item-container {
    height: 28px;
    background: white;
    padding: 7px 9px;
    text-align: right;
    color: #25486d;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
  }
  .searchbox-container {
    padding: 7px 10px 5px 8px;
    background: #d9e2ec;
    height: 36px;
    .search-list-picker {
      width: 100%;
      display: flex;
      align-items: center;
      justify-self: flex-end;
      background: #fff;
      height: 21px;
      border: 1px solid #d2dfec;
      border-radius: 4px;
      :focus-within {
        box-shadow: "rgb(93 146 244 / 25%) 0 0 0 0.2rem";
        border-color: "#5D92F4";
      }
      // padding: 0 10px;
      .MuiInputBase-input {
        font-size: 12px;
        margin-left: 23px;
        padding: 0;
      }
      img {
        position: absolute;
        width: 10.46px;
        left: 17px;
      }
    }
  }
}
