.new-shipment-modal {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .customModalContainer {
    color: black;
    margin: 8px;
    max-width: unset;
    padding: 0;
    width: 1515px;
    overflow-y: hidden;
    .modalBody {
      overflow: auto;
    }
    @media screen and (max-width: 1590px) {
      max-width: calc(91vw);
    }
    .modalHeader {
      height: 0;
    }
  }
}

.expand-item-table {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 35px;
  .customModalContainer {
    color: black;
    margin: 0px;
    height: calc(100vh - 100px);
    width: 96%;
    padding: 0;

    .modalHeader {
      display: none;
    }
    .modalBody {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: auto;
    overflow-x: auto;
      .modal-item-table {
        padding: 0px 35px 15px 35px;
        height: calc(100vh - 120px);
        min-width: 1253px;
      }
    }
  }
}

.new-company-modal {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .companyInfo {
    display: flex;
    gap: 20px;
    line-height: 2;
    .avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 30px;
      color: #464d69 !important;
      font-weight: 500;
    }
    .name {
      width: 70%;
      color: #464d69 !important;
      font-weight: 500;
    }
  }
  .bodyNewCompany {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    .title {
      width: 100%;
      text-align: center;
      color: #464d69 !important;
    }
    .metadata {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .form-input {
        gap: 27px;
      }
      .userAvatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: #464d69 !important;
        font-weight: 500;
        padding-bottom: 40px;
        .avatar-container {
          position: relative;
          overflow: hidden;
          display: flex;
          width: 100%;
          justify-content: center;
          padding-top: 20px;
        }
      }
    }
  }
  .buttomAction {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
}

.modal-content {
  height: auto;
  width: 700px;
}

.snackbar-copy-link {
  left: 50%;
  right: auto;
  bottom: 34px;
  transform: translateX(-50%);
  height: 0 !important;
  position: absolute !important;
  div {
    width: 287px;
    height: 76px;
    background: rgba(100, 126, 153, 0.5);
    border-radius: 10px;
    padding: 0px;
    text-align: center;
    div {
      div {
        img {
          position: relative;
          width: 17px;
          height: 17px;
          margin-top: 30px;
          margin-left: -100px;
        }
        span {
          position: absolute;
          margin-top: 28px;
          color: #fff;
          font-family: Roboto;
          font-size: 18px;
          letter-spacing: 0.39px;
          line-height: 21px;
          text-align: center;
          padding-left: 8px;
        }
      }
    }
  }
}
.snackbar-table-link {
  left: 50%;
  right: auto;
  bottom: 34px;
  transform: translateX(-50%);
  height: 0 !important;
  position: absolute !important;
  div {
    width: 100%;
    height: 76px;
    background: rgba(100, 126, 153, 0.5);
    border-radius: 10px;
    padding: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    span {
      position: absolute;
      color: #fff;
      font-family: Roboto;
      font-size: 18px;
      letter-spacing: 0.39px;
      line-height: 21px;
      text-align: center;
      padding-left: 8px;
    }
  }
}
