.customer-container {
  box-shadow: 0 2px 4px 2px #ccc;
  height: 100%;
  width: 100%;
  overflow: auto;
  .action-container {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    background-color: white;
    min-height: 60px;
    max-height: 60px;
    position: sticky;
    z-index: 1;
    top: 0;
    .button-add {
      border: 1px solid #25486d;
      background-color: #ffffff;
      outline: none;
      color: #25486d;
      font-weight: bold;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 0 6px;
      cursor: pointer;
      line-height: 1px;
      text-transform: uppercase;
    }
  }
  .list-item-button {
    border: none;
    padding: 6px;
    font-size: 12px;
    background-color: $aqua;
    cursor: pointer;
    color: rgb(59, 80, 100);
  }
}
