/*========== Widgets scss =========*/

.widget-heading {
  font-size: 1.625rem; //26px;
  font-weight: 500;
  color: $gray-600;
  margin-bottom: 1.875rem; //30px;
}

//Flex chart
.flex-chart {
  .rct-block-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

//To Do List widget
.to-do-list {
  ul {
    > div {
      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}

//New Mail widget
.hover-action {
  opacity: 0;
  transition: all 0.4s linear 0s;
  button,
  a {
    height: 34px;
    width: 34px;
    font-size: 1.3rem;
  }
}

.new-mail {
  > div,
  > li {
    padding: 0.625rem 0.9375rem; //10px 15px;
    @include border(1px solid, $input-border-color, bottom);
    &:hover {
      .hover-action {
        opacity: 1;
      }
    }
  }
  .subject {
    font-size: 1rem; //16px;
    font-weight: 500;
    color: $gray-900;
    margin-bottom: 0.625rem; //10px;
  }
  .message {
    color: $gray-800;
    font-size: 12px;
  }
}

.comment-section {
  ul {
    > div {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
      .comment-date {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        .hover-action {
          opacity: 1;
        }
        .comment-date {
          opacity: 0;
        }
      }
      @media (max-width: 500px) {
        display: block !important;
        .avatar-wrap {
          margin: 0 0 1rem 0 !important;
        }
        .comment-action {
          width: 100% !important;
          text-align: left !important;
        }
      }
    }
  }
}

// Weather widget
.weather-top {
  position: relative;
  .weather-over {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    padding: 0.625rem 1.875rem; //10px 30px;
  }
  .weather-head,
  .weather-temp {
    color: $white;
  }
  .weather-temp {
    h2 {
      font-size: 3.75rem;
      font-weight: 600;
      margin: 0;
    }
  }
  .weather-head {
    h4 {
      font-size: 1.5rem; //24px;
      font-weight: 500;
    }
    i {
      font-size: 5rem;
    }
  }
}

.weather-bottom {
  ul {
    li {
      @include border(1px solid, $border-color, bottom);
      padding: 0.625rem 0.9375rem; //10px 15px;
      .w-icon {
        i {
          font-size: 1.5rem; //24px;
        }
      }
    }
  }
} // Tabed Notification Widget
.notification-box {
  border: none;
  ul {
    li {
      margin-bottom: 1.25rem; //20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.message-head {
  color: $gray-600;
  i {
    font-size: 1rem;
    margin-right: 5px;
  }
}

.notify-user {
  position: relative;
  .badge {
    width: 25px;
    border-radius: 100%;
    padding: 0 !important;
    margin: 0;
    font-size: 1rem;
    height: 25px;
    line-height: 20px;
    border: 2px solid $white;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 30px;
  }
}

// New Customer
.small-btn {
  width: 33px !important;
  height: 30px !important;
  font-size: 1rem !important;
}

.new-customer-list {
  li {
    a {
      font-size: 1.1rem;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 100%;
      padding: 0;
      margin: 3px;
      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }
    }
  }
}
$NORMAL-FONT: 13px;
$FONT_FAMILY_NOTES: monospace;

//support request
.support-widget-wrap {
  ul {
    li {
      .content-title {
        width: 20%;
        color: $gray-600;
      }
    }
  }
}

//stock exchange
.stock-exchange {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      @include border(1px solid, $input-border-color, bottom);
      padding: 0.9375rem;
    }
  }
}

//share friend
.share-friend {
  .social-share {
    li {
      padding: 0;
      margin-right: 1rem;
      &:last-child {
        margin: 0;
      }
      a {
        color: $white;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
      }
    }
  }
}

//Quote Of the Day
.review-slider {
  .rct-block-title {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
    h4 {
      color: $white;
    }
  }
  .slick-dots {
    text-align: left;
    padding-left: 1rem;
    bottom: 0;
    li {
      margin: 0;
      button {
        &:before {
          color: $white;
          font-size: 0.5rem;
        }
      }
    }
  }
}

//Blog Layout Three
.blog-layout-three {
  overflow: hidden;
  .blog-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.81) 100%
    );
  }
}

//User Profile
.user-profile-widget {
  > img {
    height: 150px;
  }
  .user-avatar {
    margin-top: -6rem;
  }
}

//Latest Post
.blog-list-wrap {
  ul {
    .post-item {
      .post-img {
        width: 4rem;
      }
      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}

//Project Management
.project-managemnet-wrapper {
  .table {
    .progress {
      height: 0.3125rem;
      border-radius: 2rem;
      .progress-bar {
        border-radius: 2rem;
      }
    }
  }
}

//task-management-wrapper
.task-management-wrapper {
  .project-heading {
    padding: 0.5rem 1.5rem;
  }
  .task-title {
    width: 60%;
    h4 {
      word-wrap: break-word;
      color: $gray-600;
    }
  }
}

//Team Avatar
.team-avatar {
  ul {
    position: relative;
    margin-top: 5px;
    li {
      margin-right: 0 !important;
      > div {
        height: 2rem;
        width: 2rem;
        border: 2px solid transparent;
        transform: translateY(0) scale(1);
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        > div {
          border-color: $white;
          z-index: 999;
          transform: translateY(-4px) scale(1.02);
          box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2);
        }
      }
      > div {
        height: 1.875rem;
        width: 1.875rem;
      }
    }
    li:not(:first-child) {
      margin-left: -0.85rem;
    }
  }
}

//Activity Board
.activity-board-wrapper {
  .comment-box {
    background-color: $gray-100;
    @include border(1px solid, $input-border-color, top bottom left right);
    padding: 0.625rem 1.875rem 0.625rem 0.625rem;
  }
  .attachment-wrap {
    .list-inline-item {
      .overlay-content {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity 0.5s ease-in-out;
      }
      &:hover {
        .overlay-content {
          opacity: 1;
        }
      }
    }
  }
  .progress {
    height: 0.75rem;
    border-radius: 2.5rem;
    .progress-bar {
      border-radius: 2.5rem;
    }
  }
}

.list-group-item {
  border-color: $border-color;
}

//Trending News Widget
.trending-news-widegt {
  background-image: "";
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  left: 15.65rem;
  right: 15.6rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  .slick-arrow {
    z-index: 1;
    &.slick-prev {
      right: 5rem;
      left: auto;
    }
    &.slick-next {
      right: 3rem;
    }
    &:before {
      font-size: 1.5rem;
      opacity: 1;
    }
  }
  .trending-block {
    padding: 2.95rem 3rem;
    border-right: 1px solid $white;
  }
  @media (max-width: 768px) {
    .trending-block {
      display: none !important;
    }
    .slick-slider {
      width: 100% !important;
      .slick-list {
        height: 60px !important;
        .slider-text-wrap {
          p {
            display: none;
          }
        }
        .slider-content > div {
          width: 100% !important;
        }
      }
    }
  }
  .slick-slider {
    width: 85%;
    .slick-slide {
      border: none;
      padding: 1rem;
    }
    .month-wrap {
      .date {
        line-height: 1;
      }
    }
    .slider-content > div {
      width: 80%;
    }
  }
}

//Visitors Widget
.visitors-widget {
  .top-content {
    .count-value {
      font-size: 2.5rem;
      font-weight: $fw-semi-bold;
      line-height: 1;
    }
  }
  .collapse {
    height: 85%;
  }
}

//newslater widget
.newslater-widget {
  .collapse {
    height: 88%;
  }
}

//Subscribers Widget
.subscribers-widget {
  .overlay-content {
    pointer-events: none;
    .count-value {
      font-size: 1.75rem;
    }
  }
}

/* ============== Social Share Widget ====================*/

.social-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $block-shadow;
  border-radius: $block-border-radius;
  margin-bottom: $block-margin-bottom;
  transition: $block-transition;
  padding: $block-spacing;
  background-color: $block-bg;
  span {
    display: block;
  }
  > span {
    width: 40%;
    text-align: center;
    &:last-child {
      @include border(1px dashed, $border-color, left);
    }
  }
  .social-icon {
    width: 4.1875rem;
    height: 4.1875rem;
    background-color: $base-light;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    i {
      transition: all 0.4s ease-in-out;
      font-size: 1.75rem;
    }
  }
  &:hover {
    box-shadow: $block-hover-shadow;
    .social-icon {
      border-radius: 0 !important;
      border-color: transparent !important;
      i {
        color: $white !important;
      }
    }
    .facebook.social-icon {
      background-color: $facebook !important;
    }
    .twitter.social-icon {
      background-color: $twitter !important;
    }
    .linkedin.social-icon {
      background-color: $linkedin !important;
    }
    .google.social-icon {
      background-color: $google !important;
    }
  }
}

/*==================== Top author ================*/

.top-author-wrap {
  .slick-slider {
    height: 90%;
    .slick-track,
    .slick-list,
    .slick-slide,
    .slick-slide > div,
    .author-detail-wrap {
      height: 100%;
    }
  }
  .author-detail-wrap {
    .author-avatar {
      padding: 2.25rem 0;
      margin-top: -2px;
      .avatar-img {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        img {
          box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29);
        }
        @media (max-width: 599px) {
          top: 30px;
          img {
            height: 65px;
            width: 65px;
          }
        }
      }
    }
    .authors-info {
      min-height: 235px;
    }
    .author-contact-info {
      li {
        display: flex;
        align-items: center;
        padding: 0.75rem 0.25rem;
        &:not(:last-child) {
          @include border(1px dashed, $border-color, bottom);
        }
      }
    }
    .social-info {
      li {
        margin: 0 0.25rem;
      }
    }
    .footer-content {
      li {
        width: 33%;
        padding: 0.625rem 0;
        &:not(:last-child) {
          @include border(1px solid, $border-color, right);
        }
      }
    }
  }
  .slick-arrow {
    top: 2rem;
    z-index: 9;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    &.slick-prev {
      left: 20px;
      transform: translateX(1rem);
    }
    &.slick-next {
      right: 30px;
      transform: translateX(-1rem);
    }
    &:before {
      font-size: 1.5rem;
    }
  }
  &:hover {
    .slick-arrow {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

/*============= Top news wrap ==============*/

.top-news-wrap {
  overflow: hidden;
  .main-slider-wrap {
    .slick-slide {
      .main-slider-item {
        display: block !important;
        img {
          width: 100%;
        }
        .overlay-content {
          transition: all 0.3s ease-in-out;
          background-color: rgba(0, 0, 0, 0);
          .overlay-holder {
            background-color: rgba(0, 0, 0, 0.6);
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            p {
              height: 0;
              transition: all 0.4s ease-in-out;
              transform: translateY(1rem);
              opacity: 0;
            }
            @media (max-width: 599px) {
              margin-bottom: 0 !important;
              width: 100% !important;
              .meta-info,
              p {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
  .slider-btn-wrap {
    @media (max-width: 599px) {
      display: none;
    }
    .slick-list {
      margin: 0 2rem;
      z-index: 0;
      .slick-slide {
        cursor: pointer;
        padding: 0 10px;
        img {
          transition: all 0.3s linear;
        }
      }
      .slick-current {
        img {
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .slick-arrow {
      top: 0;
      bottom: 0;
      display: flex !important;
      align-items: center;
      width: 1.5rem;
      height: 100%;
      transition: all 0.3s ease-in-out;
      opacity: 0 !important;
      &:before {
        color: $body-color;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        font-size: 1.5rem;
      }
      &.slick-prev {
        left: 0.5rem;
        transform: translateX(1rem);
      }
      &.slick-next {
        right: 0.5rem;
        transform: translateX(-1rem);
      }
    }
  }
  &:hover {
    .overlay-content {
      background-color: rgba(0, 0, 0, 0.4) !important;
      .overlay-holder {
        background-color: rgba(0, 0, 0, 0.9) !important;
        p {
          height: 2rem !important;
          transform: translateY(0) !important;
          opacity: 1 !important;
        }
      }
    }
    .slider-btn-wrap {
      .slick-arrow {
        opacity: 1 !important;
        transform: translateX(0) !important;
        &:before {
          opacity: 1 !important;
        }
      }
    }
  }
}

/*================== Top Headlines widget============*/
.top-headlines-widget {
  @media (max-width: 500px) {
    li {
      display: block !important;
      .news-content {
        display: block !important;
        .img-wrap {
          margin: 0 0 1rem 0 !important;
          img {
            width: 100%;
          }
        }
      }
      .news-time {
        width: 100% !important;
        text-align: left !important;
      }
    }
  }
}

/*================= Twitter Feed V2 ==================*/

.twitter-feeds-widget {
  .slick-slide {
    padding: 1rem 0;
  }
  .twitter-content {
    .avatar-wrap {
      img {
        transition: all 0.3s ease-in-out;
      }
      .overlay-content {
        top: -3px;
        left: -6px;
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease-in-out;
        .badge {
          height: 2rem;
          width: 2rem;
          font-size: 1rem;
          box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29);
        }
      }
      margin-bottom: 2.5rem;
    }
  }
  &:hover {
    img {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29);
    }
    .overlay-content {
      transform: translate3d(-0.5rem, -0.5rem, 0) !important;
    }
  }
}

/*========= Responsive ==========*/

@media (max-width: 1300px) and (min-width: 320px) {
  .promo-coupon img {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 500px) and (min-width: 320px) {
  .project-managemnet-wrapper {
    .table thead tr th:last-child span {
      display: none;
    }
  }
  .team-avatar {
    display: none;
  }
  .blog-list-wrap {
    .post-item,
    .post-item .post-content {
      display: block !important;
      .post-img {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 1.875rem;
      }
    }
  }
}
// ----- Define IE hacks -----
@media all and (-ms-high-contrast: none) {
  .ie-flex {
    li {
      .media .media-body {
        flex: inherit;
      }
    }
  }
  select::-ms-expand {
    display: none;
  }
  .date-picker-icon {
    margin-bottom: 10px;
  }
  .card {
    display: block;
  }
  .notification-dropdown,
  .cart-dropdown {
    .badge {
      width: 20px;
      height: 20px;
      padding: 0;
      line-height: 1.6;
      top: 2px;
    }
  }
}
//line chart widget
.bg-color {
  .rct-block {
    * {
      color: #fff;
    }
  }
  .chart-title {
    span {
      font-size: 1.375rem;
    }
  }
}
.primary-chart {
  .rct-block {
    background-color: $primary;
  }
}
.success-chart {
  .rct-block {
    background-color: $success;
  }
}
.info-chart {
  .rct-block {
    background-color: $info;
  }
}
.danger-chart {
  .rct-block {
    background-color: $danger;
  }
}
//---- upcoming events ----
.upcoming-events-wrap {
  p {
    color: #727891;
  }
  a {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 5px;
    padding: 4px 9px;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
}
//------ ongoing-projects-wrap ------
.ongoing-projects-wrap {
  .project-list {
    li {
      margin-bottom: 8px;
      i {
        font-size: 22px;
        color: $primary;
      }
      span {
        text-transform: capitalize;
      }
    }
  }
}
.progress-xs {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
}
.notification-v2-wrap {
  .text-base {
    span {
      color: #a5a7b2 !important;
      font-weight: bold !important;
      font-size: 1rem !important;
    }
  }
}
.live-support-wrap {
  ul {
    li {
      .avatar {
        width: 65px;
        img {
          border-radius: 100%;
        }
      }
      .content {
        width: calc(100% - 65px);
      }
    }
  }
  .chat-form {
    > div {
      width: calc(100% - 100px);
    }
  }
}
.Tab-wrap {
  header {
    box-shadow: none;
    background-color: transparent;
    button {
      span {
        color: #727891;
      }
    }
  }
  .card {
    border: 0;
    > div {
      overflow-x: scroll;
      padding: 0 !important;
    }
  }
}
.Transaction-table-wrap {
  .table-wrap {
    tbody {
      td {
        font-size: 14px !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .live-support-wrap {
    .chat-form {
      > div {
        width: calc(100% - 50px);
      }
      button {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media (max-width: 374px) {
  .live-support-wrap {
    ul {
      li {
        > div {
          display: block !important;
        }
        .avatar {
          width: 100%;
        }
        .content {
          padding: 20px 0 0 !important;
          width: 100%;
        }
      }
    }
  }
}
