ul {
  list-style: none;
}

.action-buttons-container {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 50px;
  .button-add {
    border: 1px solid #25486d;
    background-color: #ffffff;
    outline: none;
    color: #25486d;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 0 6px;
    cursor: pointer;
    line-height: 1px;
    text-transform: uppercase;
  }
}
.factories-container {
  box-shadow: 0 2px 4px 2px #ccc;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .factories-main-content {
    display: flex;
    padding: 0px 5px;
    gap: 5px;
    overflow: hidden;
    height: 100%;
    ul {
      li {
        transition: all 0.5s;
        padding: 16px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e5edf7;
        min-height: 60px;
        max-height: 60px;
        cursor: pointer;

        span {
          flex: 1;
        }
      }
      li:hover {
        background-color: $primary;
      }
    }

    .sales-order-template-versions {
      user-select: none;
      flex: 1;
      font-size: 12px;
      border-left: 1px solid #e5edf7;
      overflow: auto;
      .header {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 60px;
        padding-left: 8px;
        font-weight: bold;
        background-color: $primary-light;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      ul {
        font-size: 12px;
        .active {
          font-weight: bold;
        }
        li {
          padding: 8px 8px;
          display: flex;
          min-height: 30px;
          border-bottom: 1px solid #e5edf7;
          span {
            flex: 1;
          }
        }
      }
    }
  }
  .action-container {
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    height: 70px;
    background-color: #ffffff;
  }
  .list-item-button {
    border: none;
    padding: 6px;
    font-size: 12px;
    background-color: $aqua;
    cursor: pointer;
    color: rgb(59, 80, 100);
  }
}
.hover {
  cursor: pointer;
}
.table-container {
  max-height: calc(100vh - 340px);
  overflow-y: auto;
}
