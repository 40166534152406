.user-manager-container {
  display: flex;
  box-shadow: 0 2px 4px 2px #ccc;
  height: 100%;
  width: 100%;
  z-index: 0;
  .secondary-info-container {
    display: flex;
    flex-direction: column;
    flex: 5;
  }
  header {
    background-color: $primary;
    padding: 8px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      background-color: #ffffff;
      outline: none;
      color: $primary-dark;
      font-weight: bold;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      cursor: pointer;
      line-height: 1px;
      ::before {
        content: "+";
      }
    }
  }
  .user-manager-roles {
    flex: 1;
    background-color: $primary-light;
    overflow-y: auto;
    header {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      color: #25486d;
      position: sticky;
      top: 0;
      z-index: 1;
      button {
        color: #25486d;
        display: flex;
        font-size: 12px;
        max-width: 100px;
        min-width: 93px;
        min-height: 35px;
        align-items: center;
        font-weight: 600;
        justify-content: center;
        background-color: white;
      }
    }
    ul {
      li {
        user-select: none;
        color: #25486d;
        width: 100%;
        height: 60px;
        padding: 23px 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;
        img {
          margin: 0px 8px;
        }
      }
      li.active {
        background-color: white;
        box-shadow: 0 1px 1px 1px #ccc;
      }
      li:hover {
        background-color: white;
        cursor: pointer;
      }
    }
  }
}
.user-management {
  height: 100vh;
}
.user-manager-users {
  flex: 1;
  background-color: $primary-light;
  z-index: 0;
  height: 100%;
  overflow-y: auto;
  header {
    font-size: 16px;
    font-weight: 500;
    color: #25486d;
    position: sticky;
    top: 0;
    z-index: 1;
    button {
      color: #25486d;
      display: flex;
      font-size: 12px;
      min-height: 35px;
      align-items: center;
      font-weight: 600;
      justify-content: center;
      background-color: white;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
    }
  }
  ul {
    li {
      z-index: 0;
      user-select: none;
      color: #25486d;
      width: 100%;
      height: 60px;
      padding: 23px 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
    }
    li.active {
      background-color: white;
      box-shadow: 0 1px 1px 1px #ccc;
    }
    li:hover {
      background-color: white;
      cursor: pointer;
    }
  }
}
.user-manager-permissions {
  flex: 2;
  z-index: 1;
  .subTitle {
    display: inline-flex;
    width: 100%;
    align-items: baseline;
    background: #f7fcff;
    min-height: 30px;
    font-size: 0.938rem;
    font-weight: 700;
    color: #25486d;
    padding-top: 16px;
  }
  .permissions-container {
    height: 100%;
    overflow-y: auto;
    background: #f7fcff;
  }
  .permissions-container-header {
    padding-top: 16px;
    justify-content: flex-start;
    display: inline-flex;
    width: 100%;
    align-items: baseline;
    margin-bottom: 2px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #f7fcff;
    min-height: 45px;
  }
  background-color: $white;
  header {
    background-color: $primary-light;
    padding: 21px;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul {
    padding: 0px 16px 16px 16px;
    h6 {
      font-weight: bold;
      font-size: 17px;
      color: #25486d;
    }
    li {
      display: flex;
      align-items: center;
      .permission-label-container {
        display: -webkit-box;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;
      }
      .permission-label {
        margin-right: 8px;
        color: #25486d;
        font-size: 0.938rem;
        font-weight: 400;
        font-size: 15px;
        word-wrap: break-word;
        max-width: 100%;
      }
      .permission-label-tabs {
        margin-right: 8px;
        color: #25486d;
        font-size: 0.938rem;
        font-weight: 450;
      }
      .permission-label-prefix {
        margin-right: 2px;
        color: #25486d;
        font-size: 0.938rem;
        font-weight: 500;
      }
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 78px;
  height: 20px;
  border-radius: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 28px;
}

.slider:before {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 9px;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0 1px 1px 1px #999;
  content: attr(data-negative);
  height: 16px;
  width: 38px;
  left: 1.5px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #30ce00;
  color: #30ce00;
}
input:checked:disabled + .slider {
  background-color: #ccc;
  color: #ccc;
}
input:disabled + .slider {
  background-color: #ccc;
  color: #ccc;
  cursor: default;
}

input:focus + .slider {
  box-shadow: 0 0 1px #30ce00;
}

input:checked + .slider:before {
  content: attr(data-positive);
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

input:checked + .slider.small:before {
  content: attr(data-positive);
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}
.slider.round {
  border-radius: 28px;
}

.slider.round:before {
  border-radius: 28px;
}
.user-specific-permissions {
  z-index: 2;
  padding: 0px 16px 16px 16px;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  background: #f7fcff;
  .header-user-permission {
    justify-content: flex-start;
    display: inline-flex;
    align-items: center;
    padding-top: 10px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #f7fcff;
    width: 100%;
    padding-bottom: 5px;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h6 {
    font-weight: bold;
    font-size: 17px;
    color: #25486d;
    margin-bottom: 4px;
  }
  li {
    display: flex;
    align-items: center;
    .permission-label {
      color: #25486d;
      font-size: 15px;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      max-width: calc(100vw - 1630px);
      min-width: 100px;
    }
  }
}
