.sales-order-toolbar {
  padding: 16px;
  background-color: $aqua;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.fullscreen {
  height: 100vh;
  width: 100%;
}
.sales-order-title {
  font-size: 14px;
  display: flex;
  background-color: white;
  padding: 17.5px;
}

section {
  display: flex;
  justify-content: space-between;
}

.header-container {
  position: sticky;
  background: white;
  top: 0px;
  z-index: 2;
  button {
    background-color: $primary-dark;
    color: white;
  }
}
.templates-container {
  width: 250px;
  ul {
    margin: 0;
  }
  li {
    padding: 16px;
    cursor: pointer;
  }
  li:hover {
    background-color: $primary-light;
  }
}

.custom-select {
  min-width: 100px;
  max-width: 200px;
}
input {
  background: transparent;
  border: 1px solid #ccc;
  padding-left: 2px;
  color: #464d68;
  font-family: "Roboto", sans-serif !important;
}
select {
  background: transparent;
  border: 1px solid #ccc;
  padding-left: 2px;
  color: #464d68;
  font-family: "Roboto", sans-serif !important;
}
.sales-order-form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4px 0;
  font-size: 12px;
  label {
    min-width: 50px;
  }
  input {
    font-size: 12px;
    border: 1px solid #ccc;
    width: 100px;
  }
  select {
    font-size: 12px;
    width: 170px;
    border: 1px solid #ccc;
  }
  #custom-checkbox {
    width: inherit;
  }
}
.form-container {
  width: 100%;
  display: flex;
  padding-left: 16px;
  justify-content: flex-start;
  section {
    justify-content: flex-start;
    padding: 8px;
    display: flex;
    flex-direction: column;
    .autocomple-container {
      display: flex;
      margin: 4px 0;
      width: 100%;
      height: 38px;
      label {
        font-size: 12px;
        min-width: 150px;
        margin: 0px;
        display: flex;
        align-items: center;
      }
      .input {
        width: 196px;
        height: 35px;
        border: 1px solid #ccc;
        padding-left: 2px;
        border: 1px solid #d2dfec;
        border-radius: 4px;
        div {
          p {
            font-size: 12px;
            color: #464d68;
          }
        }
      }
      .paper {
        display: block;
        flex-direction: row;
        padding-top: 0px;
        margin-top: 0px;
      }
      .menu-item {
        background: #fff;

        // fontWeight: props.isSelected ? 500 : 400,
        display: "inline-flex";
        width: auto;
        height: auto;
        text-overflow: ellipsis;
        white-space: normal;
        align-items: center;
        font-size: 12px;
        min-height: 0px !important;
      }
      .input-container {
        height: 20px;
        font-size: 12px;
        color: #ccc;
      }
      // .value-container {
      //   height: 20px;
      //   font-size: 12px;
      //   color: #ccc;
      // }
    }
  }
}
.sales-order-actions {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
}
.sales-order-modal-title {
  display: flex;
}
.input-error {
  border-color: #ff4f4f;
}
.sticky {
  position: sticky;
  bottom: 0;
  right: 0;
}



.sales-order-modal-body {
  overflow-y: unset !important;
}

.reassing-po-button {
  border-radius: 4px;
  margin-left: 5px;
  padding: 5px;
  background-color: #e5edf7;
  color: #25486d;
  &:hover {
    cursor: pointer;
  }
}

.reassign-po-container {
  margin-left: 22px;
  display: inline-flex;
}

.MuiCircularProgress-static {
  position: relative;
  top: 2px;
  background: #f2f2f2;
  border-radius: 100%;
  &:before {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    background: white;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
