/*======== App Global (Main Structure) Mention Here =======*/
#root,
.app {
  height: 100%;
  width: 100%;
}
.app-main-container {
  display: flex;
  flex-wrap: nowrap;
  .root-sidebar {
    position: absolute;
    inset: 0px;
    background: #f6fbff;
    .sidebar-class {
      position: fixed;
      box-shadow: unset !important;
      z-index: 2 !important;
    }
    .openBackdrop {
      z-index: 0 !important;
      transition: z-index 0s !important;
    }
    .transitionOut {
      transition: z-index 2.5s !important;
    }
  }
}
.rct-page-content {
  // padding: 0px 17px 0px 24px;
  overflow: hidden; //24px;
  width: calc(100vw - 86px);
}

td {
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-item-permission-modal {
  background-color: white !important;
}

.menu-item-permission-modal:hover {
  background-color: #2573c0 !important;
}
// .underline-permission-modal {
//   margin-bottom: 0;
//   padding-bottom: 0;
// }
// .paper-permission-modal {
//   width: 100%;
//   margin-top: -5px !important;
//   padding-top: 0 !important;
//   margin-bottom: 0 !important;
//   padding-bottom: 0 !important;
// }
.rct-block {
  background-color: $block-bg;
  border-radius: $block-border-radius;
  box-shadow: $block-shadow;
  border: $block-border;
  transition: $block-transition;
  margin-bottom: $block-margin-bottom;
  position: relative;
  .rct-block-content {
    padding: $block-spacing;
  }
  &:hover {
    box-shadow: $block-hover-shadow;
  }
}
.rct-block-title {
  padding: $block-title-spacing;
  position: relative;
  // @include border(1px solid, $gray-300, bottom);
  .badge {
    vertical-align: middle;
  }
  h4 {
    margin-bottom: 0;
    color: $block-title-color;
    font-size: $block-title-font-size;
    text-transform: $block-title-transform;
    font-weight: $block-title-font-weight;
    line-height: 30px;
  }
  .contextual-link {
    position: absolute;
    top: 20px;
    right: 20px;
    a {
      color: $block-contextual-color;
      font-size: $block-contextual-font-size;
      margin-right: 15px;
      &:hover {
        color: $blue;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.rct-block-footer {
  background-color: $block-bg;
  padding: $block-title-spacing;
  border-top: $block-footer-border-width solid $block-footer-border-color;
}

.card-footer {
  background-color: $block-bg;
}
.sub-title {
  padding: $block-title-spacing;
  margin-bottom: 1.25rem; //20px;
  h4 {
    margin-bottom: 0;
    color: $block-title-color;
    font-size: $block-title-font-size;
    text-transform: $block-title-transform;
    font-weight: $block-title-font-weight;
  }
}
[class*="gradient-"],
[class^="gradient-"] {
  h4 {
    color: $white;
    border-color: $white;
  }
}
/*========== Page Breadcrumb ============*/

.page-title {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.25);
  height: 66px;
  width: 100%;
  position: relative;
  left: -5px;
  z-index: 1;
  .breadcrumb {
    margin-bottom: 0;
  }
  .page-title-wrap > i {
    color: $breadcrumb-font-color;
    vertical-align: 3px;
    margin-right: 10px;
  }
  h2 {
    font-size: 20px;
    color: $breadcrumb-font-color;
    display: inline-block;
    text-transform: capitalize;
    margin: 0;
    font-weight: normal;
    color: #25486d;
  }
  .rct-creadcrumb {
    color: $breadcrumb-font-color;
    font-size: 14px;
    i {
      font-size: 12px;
      margin: 0 5px;
    }
  }
}

.focus {
  border-width: 5px;
  border-color: yellow;
  border-style: solid;
}
