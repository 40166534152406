.task-sheet-modal-input {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    /*-webkit-appearance: inner-spin-button !important;*/
    opacity: 1;
    margin-left: 5px;
  }
}

.MuiAutocomplete-popper.propertyFields {
  min-width: 215px;
  width: auto !important;
}

.contrast {
  background-color: $primary;
}

.flex-grow {
  flex: 1;
}

.flex-grow-2 {
  flex: 1 !important;
}

.flex-grow-3 {
  flex: 1 !important;
}

.text-align-center {
  text-align: center !important;
  span {
    margin: 0 !important;
  }
}

.project-dashboard-container {
  min-height: calc(100vh - 8px - 95px - 0px);
  display: flex;
  flex: 1;
  justify-content: space-evenly;
}

.task-scroll-container::-webkit-scrollbar {
  width: 4px;
}

.task-scroll-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.task-scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

/* Handle on hover */

.task-scroll-container::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.fixed-height {
  flex: none !important;
}

.transparent {
  background-color: transparent !important;
}

.sales-order-property > span {
  width: 50%;
  color: #25486d;
  font-weight: 500;
  padding-left: inherit;
}

.sales-order-property {
  display: inline-flex;
  padding: 10px 17px 10px 5px;
  position: relative;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  border-top: 1px solid $dividerColor;
  transition: all 0.4s;
  width: 100%;
  height: 100%;
  .edit-icon {
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 12px;
    cursor: pointer;
    opacity: 0;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .not-editable-field {
    font-weight: 300;
  }

  .edit-icon-shipment {
    @extend .edit-icon;
    left: 85%;
  }
  label {
    text-overflow: ellipsis;
    color: #757575;
    text-align: right;
    margin: 0;
    width: 50%;
    min-width: 50%;
    overflow: hidden;
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .no-margin {
    margin: 0 0 4px 0;
  }
  &:hover {
    background-color: $on-hover-background;
    .edit-icon {
      opacity: 1;
      -webkit-animation-name: effect-pop;
      animation-name: effect-pop;
      -webkit-animation-duration: 0.45s;
      animation-duration: 0.45s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}
.disabled-sales-order-property {
  @extend .sales-order-property;
}

.sales-order-property.editing {
  transform: scale(1.1);
  transition: all 0.2s;
  z-index: 200;
  background-color: $blue;
  display: inline-flex;
  align-items: center;
  left: 11;
  animation-duration: 0.3s;
  animation-name: grow;
  width: 100%;
  input {
    width: 100%;
    background-color: white;
    border: none;
    padding: 4px;
    margin: 0px;
    flex: 1;
    font-size: 10px;
  }
  input[type="date"] {
    padding: 2px;
  }
  select {
    width: 100%;
    background-color: white;
    border: none;
    padding: 4px;
    margin: 0px;
    flex: 1;
    font-size: 10px;
  }
  label {
    color: white;
    opacity: 1;
    font-weight: 700;
    font-size: 10px;
    width: 30px;
  }
  .icon {
    font-size: 14px;
    color: white;
    cursor: pointer;
    margin: 0;
  }
}

.project-task {
  cursor: pointer;
  padding: 8px 0px;
  display: flex;
  border-top: 1px solid $dividerColor;
  align-items: center;
  z-index: 2;
  position: relative;
  height: 40px;
  .dayoffset {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 2px;
    font-size: 13px;
    height: 25px;
    width: 32px;
    padding: 5px;
    background: white;
    box-shadow: 0 1px 4px 1px #888;
    &.early {
      color: #30d536;
      font-weight: bold;
    }
    &.late {
      color: #e02020;
      font-weight: bold;
    }
    &.disabled {
      opacity: 0.3;
      color: #25486d;
      box-shadow: none;
      background: unset;
    }
  }
  .task-user {
    padding: 0px 4px 0px 12px;
    display: flex;
    align-items: center;
    width: 20%;
    gap: 5px;
    &:hover {
      .arrowButton {
        opacity: 1;
      }
    }

    .arrowButton {
      opacity: 0;
      min-width: 12px;
      width: 12px;
    }
    div {
      width: 26px;
      height: 26px;
    }
  }
  &.to-edit {
    background: $sales-order-color;
    z-index: 3;
    border-left: 1px solid #d4dfed;
    border-top-left-radius: 4px;
    border-right: 1px solid #d4dfed;
    border-top-right-radius: 4px;
  }
  &.completing {
    position: relative;
    &:before {
      content: "";
      height: 100%;
      position: absolute;
      opacity: 0.7;
      top: 0;
      left: 0;
      transition: 6s ease-out;
      -webkit-animation-name: custom-progress;
      animation-name: custom-progress;
      -webkit-animation-duration: 6s;
      animation-duration: 6s;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
  &.complete {
    opacity: 0.55;
  }
  &.completeOpacity {
    opacity: 0.55;
    font-weight: 500;
    transition: opacity 2s ease;
  }

  &.pendingOpacity {
    opacity: 0.7;
    font-weight: 500;
    transition: opacity 2s ease;
  }

  &.completeSize {
    height: 0;
    transition: height 2s ease-out;
    overflow: hidden;
    border: unset;
  }

  &.transition {
    opacity: 0.55;
    font-weight: 500;
    transition: opacity 2s ease;
  }
}

.option-to-edit-container {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  transition: all 2s;
  transform: none;
  border-top: transparent;
  position: relative;
  z-index: 1 !important;
  border-left: 1px solid #d4dfed;
  border-bottom-left-radius: 4px;
  border-right: 1px solid #d4dfed;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid #d4dfed;
  min-height: 40px;
  &.dissapear {
    z-index: 0 !important;
    transform: translateY(-50px);
  }
  .task-edit-and-delete-option {
    display: flex;
    justify-content: center;
    align-items: center;
    .edit-task {
      margin: 0;
      padding: 0px 24px;
      font-weight: 700;
      color: #578ec7;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .edit-icon {
      color: #ff9494;
      width: 13px;
      height: 13px;
      position: relative;
      left: 1px;
      bottom: 1px;
      &:hover {
        cursor: pointer;
      }
    }
    .circular-progress {
      display: flex;
      justify-content: center;
      align-items: center;
      .circular-progress-MU {
        position: absolute !important;
        top: -6;
        left: -6;
        z-index: 1;
      }
      img {
        z-index: 2;
      }
    }
  }
}

.delete-task {
  color: #ff9494;
  width: 20;
  height: 20;
  &:hover {
    cursor: pointer;
  }
}

.completing-task {
  position: relative;
  height: 100%;
  position: absolute;
  // background: #464d69;
  opacity: 0.5;
  top: 0;
  left: 0;
  transition: 0.9s ease-out;
  -webkit-animation-name: custom-progress;
  animation-name: custom-progress;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  &:before {
    content: "";
  }
}

.note-secction-cancel-icon {
  color: #25486d;
  &:hover {
    background: #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
}

.modal-notes-section {
  height: 99%;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: unset;
  margin-top: 25px;
  .document-note-open {
    background: #fbfbfb;
    position: absolute;
    left: 766px;
    top: 0;
    width: 578px;
    border-radius: 0px 8px 8px 0px;
    height: calc(100vh - 78px);
    box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.25) inset;
    border: 0;
  }
  .modal-content {
    border: 0;
    .modal-header {
      padding: 15px 15px 0px 15px;
      .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
        width: 100%;
        text-align: center;
        .title-note {
          font-weight: 400;
          margin-top: 23px;
          margin-left: 21px;
          margin-bottom: 0;
          color: #25486d;
        }
      }
    }
    height: 100%;
    width: 801px !important;
    box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.25);
    // .modal-body {
    //   width: auto;
    //   height: auto;
    //   display: flex;
    //   flex-direction: column;
    //   padding: 0px 40px 35px 42px;
    // }
  }
}

.marginMoved {
  transition: margin-right 0.5s ease;
  margin-right: 35%;
}
.marginInit {
  transition: margin-right 0.5s ease;
  margin-right: unset;
}

.new-info-shipment {
  .sales-order-property {
    .sales-order-property.editing {
      background-color: $blue;
    }
  }

  .disabled-sales-order-property {
    font-weight: 300;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    &:hover {
      background-color: unset;
    }
    .helper-span::before {
      content: " _ ";
      opacity: 0;
    }
  }
}
.sales-order-property.secondaryClassName {
  width: 580px;
  label {
    min-width: 165px;
    width: 165px;
    font-size: 20px;
  }
  span {
    width: 66%;
    font-size: 20px;
  }
  .edit-icon {
    right: 0px;
    left: unset;
    width: 25px;
  }
  .sales-order-property.editing {
    top: 0px;
    left: 18px;
    transform: scale(1.1);
    transition: all 0.2s;
    animation-duration: 0.3s;
    animation-name: growCustomized;
    width: 92%;
    span {
      width: 15px;
    }
  }
  @keyframes growCustomized {
    to {
      width: 100%;
      transform: scale(1.1);
    }
    from {
      width: 92%;
      transform: scale(1);
    }
  }
}
.content-card.flex-grow-2:last-child {
  max-width: 400px;
  min-width: 380px;
}

.content-card.flex-grow-2:first-child {
  max-width: 750px;
  min-width: 720px;
}

input[type="text"]::placeholder {
  color: #25486d;
}

@keyframes grow {
  to {
    width: 280px;
    transform: scale(1.1);
  }
  from {
    width: 100%;
    transform: scale(1);
  }
}

.content-card {
  &:hover {
    & .resize-tab {
      display: block;
      opacity: 1;
    }
  }
  & .resize-tab {
    transition: all 0.5s;
    opacity: 0;
    display: none;
    position: absolute;
    width: 15px;
    background-color: rgb(246, 251, 255);
    min-height: calc(100vh - 16px - 66px - 20px);
    z-index: 50;
    cursor: grab;
    writing-mode: vertical-rl;
    img {
      height: 36px;
      width: 10px;
      display: block;
      transform-origin: center;
      transform: rotate(180deg);
      margin: 2px 0;
    }
    &.left span {
    }
    & span {
      transform: rotate(180deg);
      transform-origin: center;
      display: flex;
      justify-content: center;
      user-select: none;
      position: absolute;
      text-orientation: sideways;
      height: 250px;
      color: #c8c8c8;
      font-family: Roboto;
      font-size: 10px;
      width: 100%;
      top: 10px;
      align-items: center;
      img {
        height: 10px;
        width: 10px;
        object-fit: contain;
        margin-bottom: 6px;
      }
    }
    &:first-child {
      left: -15px;
    }
    &:last-child {
      right: -15px;
    }
    &:hover {
      opacity: 1;
      display: block;
    }
  }
}

.large-podash-container {
  min-width: 1650px;
}
