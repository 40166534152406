/*====== All Custom Variable Interduce Here ======*/
$light-yellow: #fff59d;
$twitter: #52aeff;
$facebook: #3b5998;
$google: #f13a30;
$pinterest: #d32f2f;
$instagram: #8d6e63;
$rss: #ff9800;
$tumblr: #607d8b;
$linkedin: #4875b4;
$dribbble: #f06292;
$youtube: #d50000;
$github: #424242;
$skype: #4fc3f7;
$salesOrderBackgroundColor: #ebf3d4;
$salesOrderBorderColor: #b6c193;
$purchaseOrderBackgroundColor: #e6f0f9;
$purchaseOrderBorderColor: #90aed1;
$shipmentBordeColor: #daa2e3;
$dividerColor: #87a3c2;
$shipmentBackgroundColor: #f6e6f9;
$backgroundBlue: #b1d9fe;
$backgroundBlueAction: #65b0f6;
$backgroundGreen: #c5e569;
$backgroundGreenAction: #9dc725;
$backgroundPink: #f6c4ff;
$backgroundPinkAction: #ea84fc;

$colors: () !default;
$colors: map-merge(
  (
    "light-yellow": $light-yellow,
    "twitter": $twitter,
    "facebook": $facebook,
    "google": $google,
    "pinterest": $pinterest,
    "instagram": $instagram,
    "rss": $rss,
    "tumblr": $tumblr,
    "linkedin": $linkedin,
    "dribbble": $dribbble,
    "youtube": $youtube,
    "github": $github,
    "skype": $skype,
  ),
  $colors
);

$light-yellow: $light-yellow;
$twitter: $twitter;
$facebook: $facebook;
$google: $google;
$pinterest: $pinterest;
$instagram: $instagram;
$rss: $rss;
$tumblr: $tumblr;
$linkedin: $linkedin;
$dribbble: $dribbble;
$youtube: $youtube;
$github: $github;
$skype: $skype;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "light-yellow": $light-yellow,
    "twitter": $twitter,
    "facebook": $facebook,
    "google": $google,
    "pinterest": $pinterest,
    "instagram": $instagram,
    "rss": $rss,
    "tumblr": $tumblr,
    "linkedin": $linkedin,
    "dribbble": $dribbble,
    "youtube": $youtube,
    "github": $github,
    "skype": $skype,
  ),
  $theme-colors
);

// html font
$html-font-size: 16px;

// Block Settings
$block-bg: $white;
$block-border-radius: 5px;
$block-border: none;
$block-spacing: 1.25rem 1.25rem; //20px 20px;
$block-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-hover-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-margin-bottom: $grid-gutter-width; //24px
$block-transition: all 0.4s ease-in-out 0s;

$block-title-color: $gray-900;
$block-title-spacing: 0.9375rem 1.25rem; //15px 20px;
$block-title-font-size: 1rem; //16px
$block-title-font-weight: 500;
$block-title-transform: capitalize;

$block-contextual-color: $gray-600;
$block-contextual-font-size: 1rem;

$block-footer-border-width: 1px;
$block-footer-border-color: $gray-300;

//Breadcrumb Variable
$breadcrumb-font-size: 1.2rem; //30px;
$breadcrumb-font-color: $gray-900;

// Dark theme
$dark-bg: #2c3644;
$dark-bg-color: #fff;
$dark-bg-container: #303030;
$dark-border-color: rgba(0, 0, 0, 0.1);
$dark-placeholder: #303030;
$dark-secondary-bg: #434f5a;

// Input Fields
$input-lg-height: 52px;
$input-radius: 40px;

// Gradient Theme Color
$sidebar-dark-gradient: linear-gradient(0deg, #daebf3 0%, #daebf3 100%);
$sidebar-dark: #dcebf3;
$overlay-light: rgb(255, 255, 255);
$gradient-primary: linear-gradient(180deg, #daebf3 0%, #daebf3 100%); // Primary
$gradient-purple: linear-gradient(0deg, #f53844 0%, #42378f 100%); // Purple
$gradient-success: linear-gradient(180deg, #00d0bd 0%, #007adf 100%); // Success
$gradient-warning: linear-gradient(0deg, #b02e0c 0%, #ffb70f 100%); // warning
$gradient-danger: linear-gradient(0deg, #b02e0c 0%, #ffb70f 100%); // danger

// Navbar Varibale
$navbar-height: 4rem; //80px;
$navbar-bg: $white;
$hamburger-icon-color: $gray-600;
$navbar-icon-height: 35px;
$navbar-icon-width: 35px;
$navbar-link-color: $white;

// Footer Variable
$footer-height: 50px;
$footer-bg: $navbar-bg;
$footer-box-shadow: none;

// Square Box
$square-font-size: 0.5; // 50% relative to the container
$square-box: (10, 15, 20, 30, 40, 50, 60, 70, 80, 100, 120);

// Sidebar Variable
$sidebar-width: 86px; //280px;
$sidebar-box-shadow: none;

$sidebar-link-fontsize: 1rem; //14px
$sidebar-link-font-weight: 400;
$sidebar-link-font-color: #365b7c;
$sidebar-link-transform: capitalize;
$sidebar-link-spacing: 0.9375rem 2rem; //15px 40px;

$sidebar-icon-font-size: 1rem; //14px;

$sidebar-hover-bg: rgba(0, 0, 0, 0.22);
$sidebar-hover-color: $white;

$sidebar-submenu-font-size: 1rem; //14px
$sidebar-submenu-font-color: #365b7c;
$sidebar-submenu-spacing: 0.9375rem 2.5rem 0.9375rem 4.375rem;

// Mini Sidebar Variable
$mini-sidebar-width: 85px;

// Horizontal Menu sidebar Variable
$hoz-menu-bg: #ffffff;
$hoz-nav-link-color: #464d69;
$hoz-nav-font-size: 0.875rem; //14px
$hoz-nav-link-spacing: 0.9375rem 1.563rem 0.9375rem 1.563rem; //15px 30px;
$hoz-nav-link-hover-bg: #fafafa;
$hoz-menu-height: 3.125rem; //50px;
$hoz-sub-menu-width: 15rem;
$hoz-box-shadow: none;
$hoz-sub-menu-box-shadow: none;

// Social Link Variable
$social-icon-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.19);

// Weather Widget Variables
$weather-bg: "";

// boxed layout
$boxed-bg: "";
$dark-layout-bg: "";

// Table Varibale
$table-tbody-font-size: 14px;
$table-tbody-font-weight: 300;

//Quick link
$dropdown-link-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
$base-light: #fafafa;

//Font Weight
$fw-semi-bold: 500;
$fw-bold: 700;
$fw-light: 300;
$fw-normal: 400;

//Custom Colors
$aqua: #e1ebff;
$primary: #c0d5ee;
$primary-light: #e5edf7;
$primary-dark: #25486d;
$secondary: #f6fbff;
$thumbScrollColor: #898989;

//Buttons
$button-standard: #afaeae;
$button-success-bg: #2576c0;
$button-cancel-bg: #777777;
$button-danger-bg: #bd2b2b;

// Font color
$font-text-primary: #25486d;
$font-text-secondary: #798a9b;
$font-text-version: #828282;
$on-hover-background: #fffce6;
$selected-background: #ebebeb;
$sales-order-color: #e6f0f9;
$shipment-color: #f6e6f9;

$salesOrderBackgroundColor: #ebf3d4;
$salesOrderBorderColor: #b6c193;
$purchaseOrderBackgroundColor: #e6f0f9;
$purchaseOrderBorderColor: #90aed1;
$shipmentBordeColor: #daa2e3;
$dividerColor: #87a3c2;
