.listbox {
  .option {
    font-size: 14px;
    padding: 4px 8px;
    background: white;
  }
  .option[data-focus="true"] {
    background-color: #e6f0f9 !important;
  }
  .option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0);
  }
}

.autocomplete-sales-order-modal {
  height: 35px;
  width: 200px !important;

  .text-field {
    padding: 0px;
    height: 100%;
    div {
      height: 100%;
      padding: 0px;
      position: absolute;
      right: 0px;
    }
    .inputAdornedEnd {
      padding: 8px 39px 8px 8px !important;
      height: 0px;
      height: 17px !important;
      border-radius: 4px;
      border: 1px solid gray;
    }
    .adornedend {
      border-radius: 0px !important;
      border: none !important;
      :hover {
        border-radius: 4px !important;
        border-color: white !important;
      }

      padding: 0px !important;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        top: 0;
        button {
          width: 40px;
          min-width: 0;
        }
      }
    }
  }
  .notchedOutlineSO {
    border-radius: 4px !important;
    border: 1px solid rgb(210, 223, 236) !important;
  }
}

.autocomplete-tag-root {
  width: 90%;
  min-height: 35px;
  height: 35px;
  .inputRoot {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 2px;
    fieldset {
      border: 1px solid #d2dfec;
      :hover {
        border: 1px solid #d2dfec;
      }
    }
  }
  .input {
    padding: 0px !important;
    height: 35px !important;
    font-size: 14px;
    color: #25486d;
  }
  .endAdorment {
    width: 24px;
    height: 24px;
    .clearIndicator {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
  .textField-root {
    height: 35px;
    min-height: 35px;
    margin-top: 0;
  }
}

.inputWithButton {
  position: relative;
  width: 140;
}

.inputWithButton input {
  width: 100%;
}
.inputWithButton input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.inputWithButton svg {
  position: absolute;
  bottom: 1px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}
.inputWithButton button {
  position: absolute;
  right: 0;
  height: 22px;
}
